<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="sendNotification" :timeout="4000" top>
      <span>Notification Send successfully.</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="sendNotification = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-card>
      <v-card-title> <h3>Send Notification</h3></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="notification">
              <v-container class="py-2">
                <v-row>
                  <v-col cols="6" md="6">
                    <v-checkbox
                      v-model="isSendClubGlugg"
                      label="Only For Club Glugg"
                      @click:append="isSendClubGlugg = !isSendClubGlugg"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field
                      label="Title"
                      class="purple-input"
                      v-model="title"
                      :rules="notificationRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field
                      label="Message"
                      class="purple-input"
                      v-model="message"
                      :rules="notificationRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0"
                      @click="submitNotification"
                      :loading="loading"
                      >Send</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      message: "",
      isSendClubGlugg: false,
      notificationRule: [(v) => !!v || "Field is required"],
      sendNotification: false,
      loading: false,
    };
  },
  methods: {
    submitNotification() {
      if (this.$refs.notification.validate()) {
        this.loading = true;
        let data = {
          title: this.title,
          message: this.message,
          isSendClubGlugg: this.isSendClubGlugg,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "sendNotification", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.sendNotification = true;
              this.title = null;
              this.message = null;
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
</style>